import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import solutioninteract from "../images/solution-interact.png"
import inboxfeaturetwo from "../images/inbox-feature-two.png"
import Finalctablock from "../components/Finalctablock"

const inbox = () => {
  return (
    <Layout>
      <div className="container">
        <section className="inbox-hero">
          <div className="inbox-herotxt">
            <h1>Deliver smart interactions across every touchpoint</h1>
            <p>
              Conveniently manage and respond to every message across multiple
              channels, all from one platform.
            </p>
            <a href="">
              <button className="btn btn-main btn-hero">BOOK DEMO</button>
            </a>
          </div>

          <img src={solutioninteract} className="inbox-heroimg" alt="" />
        </section>
      </div>

      <div className="product-grad">
        <div className="container">
          <section className="product-underhero">
            <div className="point reviews-point-one">
              <h4>Be the easiest to buy from</h4>
              <p>
                Connect with your leads or customers the way they prefer,
                whether they started on Facebook, Google, or SMS.
              </p>
            </div>

            <div className="point reviews-point-two">
              <h4>Centralise interactions</h4>
              <p>Respond quickly without jumping from inbox to inbox. </p>
            </div>

            <div className="point reviews-point-three">
              <h4>No waiting around</h4>
              <p>
                Have a complete history of every conversation, no waiting around
                by the phone for you, or your customers.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid inbox-feature-one">
          <img src={solutioninteract} alt="" />
          <div className="featurestxt">
            <h3>Deliver a modern customer experience</h3>
            <p>
              Use InsideHero to answer questions, provide quotes, schedule
              appointments, send reminders, get feedback, close the deal, and
              provide after-purchase support - the possiblities are endless.
            </p>
          </div>
        </section>
      </div>

      <div className="messaging-stats-grad">
        <div className="container">
          <section className="messaging-stats-section">
            <h2>Todays consumers want to text not call</h2>

            <div className="messaging-stats-block">
              <div className="message-stats stat-one">
                <h3>95%</h3>
                <p className="message-stat-subtitle">of messages read</p>
                <p>
                  Texts are opened 99% of the time. 95% of texts are read within
                  5 minutes.
                </p>
              </div>

              <div className="message-stats stat-two">
                <h3>3x</h3>
                <p className="message-stat-subtitle">higher conversion</p>
                <p>
                  Reviews and referrals requested via text message vs requested
                  via. email.
                </p>
              </div>

              <div className="message-stats stat-three">
                <h3>10x</h3>
                <p className="message-stat-subtitle">faster than calling</p>
                <p>Texting is the new standard for easy contact.</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="productstripefeaturetop-left"></div>
        <section className="features-grid inbox-feature-two">
          <div className="featurestxt">
            <h3>Make millions feel like one in a million</h3>
            <p>
              Customise and save message templates to scale your messaging with
              a personalised touch.
            </p>
          </div>

          <img src={inboxfeaturetwo} alt="" />
        </section>
        <div className="productstripefeaturebottom-right"></div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="container">
          <h2>Features</h2>
          <section className="product-features">
            <div className="product-features-point product-features-point-one">
              <h4>Notifications</h4>
              <p>Be alerted and respond when you get a new messsage</p>
            </div>

            <div className="product-features-point product-features-point-two">
              <h4>Time-saving templates</h4>
              <p>
                Send personalised messages or make it easier with customisable
                templates.
              </p>
            </div>

            <div className="product-features-point product-features-point-three">
              <h4>Permissions</h4>
              <p>Give the right people access to the right conversations.</p>
            </div>

            <div className="product-features-point product-features-point-four">
              <h4>Inbox organisation</h4>
              <p>
                Mark messages as read or unread. Keep track of messages that
                still need a response.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="productstripefeaturetop-left"></div>
        <Finalctablock />
      </div>
    </Layout>
  )
}

export default inbox
